import Vue from 'vue';
import i18n from './i18n';

i18n.locale = window.__LANGUAGE__ ?? 'ru';

const globalData = new Vue({
    data: { $tenant: null },
});

setTimeout(() => {
    globalData.$data.$tenant = window.tenantCode || window.__Tenant__ || 'RR';
});

Vue.mixin({
    computed: {
        $tenant: {
            get: () => globalData.$data.$tenant,
            set: (val) => { globalData.$data.$tenant = val; },
        },
    },
});

const vskArticles = new Vue({
    el: '#vskArticles',
    components: {
        VskArticles: () => import('../main_old/sections/VskArticles/VskArticles.vue'),
    },
});

const profileNav = new Vue({
    el: '#profileNav',
    i18n,
    components: {
        ProfileNav: () => import('../main_old/sections/ProfileNav/ProfileNav.vue'),
    },
});

window.VueRoot = {
    vskArticles,
    profileNav,
};

let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';
